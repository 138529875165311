import React from "react";
import { useContext, useState } from "react";
import AppLayout from "../shared/app-layout/AppLayout";
import { HStack, Heading, Icon, Select, Box } from "@chakra-ui/react";
import { mdiChartBox } from "@mdi/js";
import { ChangeEventHandler } from "react";
import { fetchOrgWatchTime } from "../services/AnalyticsService";
import { useQuery } from "react-query";
import { StoreContext } from "../stores/helpers/context";
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLine,
} from "victory";
import { CommonUtils } from "../utils/CommonUtils";
import { VictoryTheme } from "victory-core";
import Chart from "./Chart";

export default function AnalyticsPage() {
  const store = useContext(StoreContext);
  const orgId = store.organizationRoleStore?.selectedOrganizationId as string;

  const [start, setStart] = useState<Date>(
    CommonUtils.ranges[CommonUtils.defaultRange].start
  );
  const [end, setEnd] = useState<Date>(new Date());

  const query = useQuery(["orgWatchTime", start, end], () =>
    fetchOrgWatchTime(orgId, start, end)
  );

  const _handleRangeChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setStart(new Date(e.target.value));
  };

  return (
    <AppLayout
      colorScheme="secondary"
      startLogo={
        <Icon fontSize="4rem">
          <path fill="currentColor" d={mdiChartBox}></path>
        </Icon>
      }
      title="Analytics"
      description={
        <>
          Gain full insights from the analytics of your watch time performance
          of your organisation, your most popular playlists and content.
        </>
      }
    >
      <HStack
        w="full"
        mb="8"
        wrap="wrap"
        justify="space-between"
        alignItems="center"
      >
        <Heading as="h2">Reports</Heading>

        <Select maxWidth="md" onChange={_handleRangeChange}>
          {CommonUtils.ranges.map(({ label, start }) => (
            <option value={start.toDateString()}>{label}</option>
          ))}
        </Select>
      </HStack>
      <Box maxW="60%" margin="auto">
        <Chart
          label="Organization Watch time (minutes)"
          totalCount={+((query.data?.total || 0) / 1000 / 60).toFixed(2)}
          data={query.data?.data.map((x) => {
            return {
              value: +(x.value / 1000 / 60).toFixed(2),
              time: x.time,
            };
          })}
        />
      </Box>
    </AppLayout>
  );
}
